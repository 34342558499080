body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.fade-in-section {
  opacity: 0;
  transform: translateY(20vh);
  visibility: hidden;
  transition: opacity 1200ms ease-out, transform 600ms ease-out,
    visibility 1200ms ease-out;
  will-change: opacity, transform, visibility;
}

.fade-in-section2 {
  opacity: 0;
  transform: translateY(20vh);
  visibility: hidden;
  transition: opacity 1500ms ease-out, transform 700ms ease-out,
    visibility 1500ms ease-out;
  will-change: opacity, transform, visibility;
}

.fade-in-section3 {
  opacity: 0;
  transform: translateY(20vh);
  visibility: hidden;
  transition: opacity 1800ms ease-out, transform 800ms ease-out,
    visibility 1800ms ease-out;
  will-change: opacity, transform, visibility;
}

.fade-in-section4 {
  opacity: 0;
  transform: translateY(20vh);
  visibility: hidden;
  transition: opacity 2100ms ease-out, transform 900ms ease-out,
    visibility 2100ms ease-out;
  will-change: opacity, transform, visibility;
}

.fade-in-section.is-visible {
  opacity: 1;
  transform: none;
  visibility: visible;
}

.fade-in-section2.is-visible {
  opacity: 1;
  transform: none;
  visibility: visible;
}

.fade-in-section3.is-visible {
  opacity: 1;
  transform: none;
  visibility: visible;
}

.fade-in-section4.is-visible {
  opacity: 1;
  transform: none;
  visibility: visible;
}

/* Just for presentational purposes, please disregard in your own implementation */
.App {
  max-width: 100%;
  padding: 1em;
}

.box {
  padding: 50px;
  margin: 20px 0;
}

.box span {
  background-color: rgba(255, 255, 255, 0.5);
  display: inline-block;
  padding: 5px;
  border-radius: 3px;
}

.orgchart {
  background-image: none !important;
}

.activity-article img {
  max-width: 85vw;
}